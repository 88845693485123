import { lazy, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

// project imports
import { useNavigate, useParams } from 'react-router-dom';
import SurveyService from 'services/survey.service';
import MultiStepForm from './MultiStepForm';
import * as yup from 'yup';
import { Box, Button, Card, CardContent, Grid, LinearProgress, Skeleton, Typography } from '@mui/material';
import { useTheme, ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Loadable from 'ui-component/Loadable';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';
import { find, first, isEmpty, isUndefined, now } from 'lodash';
import FormStep from './FormStep';
import AuthWrapper1 from '../AuthWrapper1';
import AnswerService from 'services/answer.service';
import ResponseService from 'services/response.service';
import useAuth from 'hooks/useAuth';
import YupValidatorGenerator from 'utils/YupValidatorGenerator';
import { IconAxe, IconSquareMinus } from '@tabler/icons';
import useQuestionStore from 'zustand_store/useQuestionStore';
import MessageQuestion from 'views/questions/MessageQuestion';
import ListSkeleton from 'ui-component/custom/ListSkeleton';
import LogoSection from 'layout/MainLayout/LogoSection';
import PropTypes from 'prop-types';
import logo from 'assets/images/surveyFlip-logo-icon.svg';

// Function to generate background style based on survey data
const getBackgroundStyle = (survey) => {
    console.log('DATADOG', survey?.meta?.design);

    if (survey?.meta?.design?.design?.backgroundImage) {
        return `url(${survey.meta.design.design?.backgroundImage}) center/cover no-repeat ${
            survey?.meta?.design?.backgroundColor || survey?.meta?.background_color || '#ffffff'
        }`;
    }
    if (survey?.meta?.design?.backgroundImage) {
        return `url(${survey.meta.design?.backgroundImage}) center/cover no-repeat ${
            survey?.meta?.design?.backgroundColor || survey?.meta?.background_color || '#ffffff'
        }`;
    }
    return `${survey?.meta?.design?.backgroundColor || survey?.meta?.background_color || '#ffffff'}`;
};

// Theme configuration
const lightTheme = (survey) =>
    createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#3f51b5'
            },
            secondary: {
                main: '#f50057'
            },
            background: {
                default: '#f5f5f5',
                paper: '#ffffff'
            }
        },
        typography: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            h2: {
                fontWeight: 700,
                color: survey?.meta?.design?.questionColor || '#000000'
            },
            p: {
                color: survey?.meta?.design?.questionColor || '#000000'
            },
            // Question title color ( value will be dynamic )
            h5: {
                color: survey?.meta?.design?.questionColor || '#000000'
            },
            // Ensure all input text uses this color
            input: {
                color: survey?.meta?.design?.answerColor || '#000000'
            },
            emoji: {
                color: survey?.meta?.design?.answerColor || '#000000'
            }
        },
        shape: {
            borderRadius: 8
        },
        button: {
            color: survey?.meta?.design?.buttonTextColor || '#ffffff',
            backgroundColor: survey?.meta?.design?.buttonColor || '#3f51b5'
        },
        // Added custom background properties
        customBackground: {
            main: {
                background: getBackgroundStyle(survey),
                minHeight: '100vh'
            }
        },
        components: {
            // Add global styling for all input components
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        color: survey?.meta?.design?.answerColor || '#000000',
                        '& input': {
                            color: survey?.meta?.design?.answerColor || '#000000'
                        },
                        '& textarea': {
                            color: survey?.meta?.design?.answerColor || '#000000'
                        },
                        '& select': {
                            color: survey?.meta?.design?.answerColor || '#000000'
                        }
                    }
                }
            }
        }
    });

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius * 4,
    padding: theme.spacing(0.5, 2.5),
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
        flex: 1,
        padding: theme.spacing(0.75, 2)
    }
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    height: 4
}));

const SurveyLightTheme = ({ survey }) => {
    const { shortcode } = useParams();
    const [questions, setQuestions] = useState([]);
    const [isinitiallyLoaded, setIsinitiallyLoaded] = useState(false);
    const [yupValidatorSchema, setYupValidatorSchema] = useState({});
    const stateQuestions = useMasterSurveyResponseStore((state) => state.questions);
    const SET_DANGEROULSY_STATE_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_DANGEROULSY_STATE);
    // const [survey, setSurvey] = useState({});
    const [resolveCurrentQuestionAnswer, setResolveCurrentQuestionAnswer] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [SelectedQuestionComponent, setSelectedQuestionComponent] = useState({});
    const SET_QUESTIONS_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_QUESTONS);
    const RESET_RESPONSES_TO_STORE = useMasterSurveyResponseStore((state) => state.RESET_RESPONSES);

    const SET_CURRENT_QUESTON_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_CURRENT_QUESTON);
    const getNextQuestion = useMasterSurveyResponseStore((state) => state.getNextQuestion);
    const getPrevQuestion = useMasterSurveyResponseStore((state) => state.getPrevQuestion);
    const responseTracker = useMasterSurveyResponseStore((state) => state.responseTracker);
    const ADD_RESPONSE_TO_STORE = useMasterSurveyResponseStore((state) => state.ADD_RESPONSE);
    const SET_SURVEY_QUESTIONS_TO_STORE = useQuestionStore((state) => state.SET_SURVEY_QUESTIONS);
    const TRACK_RESPONSES_TO_STORE = useMasterSurveyResponseStore((state) => state.TRACK_RESPONSES);
    const SET_RULE_ENGINE_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_RULE_ENGINE);
    const SET_USERID_SURVEYTOKEN_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_USERID_SURVEYTOKEN);
    const STATE_SETTER_INTO_STORE = useMasterSurveyResponseStore((state) => state.STATE_SETTER);
    const responses = useMasterSurveyResponseStore((state) => state.responseContainer.responses);
    const hasPrevious = useMasterSurveyResponseStore((state) => state.hasPreviousQuestion);
    const STORE_STATE = useMasterSurveyResponseStore((state) => state);
    const SET_DANGEROULSY_RESPONSETRACKER = useMasterSurveyResponseStore((state) => state.SET_DANGEROULSY_RESPONSETRACKER);
    const storeCurrentShortcode = useMasterSurveyResponseStore((state) => state.survey_short_code);
    const multiStepFormEl = useRef();
    const responseContainer = useMasterSurveyResponseStore((state) => state.responseContainer);

    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [animationDirection, setAnimationDirection] = useState('next');

    // Create theme with survey data
    const dynamicTheme = lightTheme(survey);

    const handleQuit = () => {
        RESET_RESPONSES_TO_STORE();
        SET_DANGEROULSY_RESPONSETRACKER([]);
        navigate('/surveys');
    };

    const renderInitialData = async () => {
        // const surveyResponse = await SurveyService.surveyHandler(shortcode);

        // setSurvey(survey); // local
        setQuestions(survey.questions); // local
        // await SET_QUESTIONS_INTO_STORE(data.questions); // State Manager
        SET_SURVEY_QUESTIONS_TO_STORE(survey.questions);

        // if the user is authenticate then check if the survey was resumed or not
        if (user) {
            const response = await SurveyService.isResumedSurvey(survey.id, user.id);
            // eslint-disable-next-line no-extra-boolean-cast
            if (!!response.data) {
                // The survey is resumed
                STATE_SETTER_INTO_STORE(response.data); // STATE MANAGER
                // return false;
            } else {
                // The Survey is not resumed, it's new
                RESET_RESPONSES_TO_STORE();
                SET_USERID_SURVEYTOKEN_INTO_STORE(user.id, survey.meta.short_code); // State Manager
                SET_QUESTIONS_INTO_STORE(survey.questions); // State Manager
                SET_RULE_ENGINE_INTO_STORE(survey.meta.rule_engine || []); // State Manager
                // Setting first Question by default
                // if (responseTracker.length === 0) {
                // console.log('first', first(Object.values(data.questions)));
                setCurrentQuestion(first(Object.values(survey.questions)));
                // }
            }
        } else {
            SET_USERID_SURVEYTOKEN_INTO_STORE(0, survey.meta.short_code); // State Manager
            SET_QUESTIONS_INTO_STORE(survey.questions); // State Manager
            SET_RULE_ENGINE_INTO_STORE(survey.meta.rule_engine || []); // State Manager
            // Setting first Question by default
            if (responseTracker.length === 0) {
                setCurrentQuestion(first(Object.values(survey.questions)));
            }
        }
        setIsinitiallyLoaded(true);
    };

    //  Master Submit Handler
    const submitHandler = async (values, actions) => {
        setIsinitiallyLoaded(false);
        ADD_RESPONSE_TO_STORE(currentQuestion.id, values.answer, values.answered_at);
        const id = currentQuestion.id;
        const answer = values.answer;
        let scorePayload = [];

        if (survey.survey_type === 'Assessment' && responseContainer.responses.length > 0) {
            scorePayload = responseContainer.responses.map((res) => ({
                choiceId: Number(res.answer.value),
                questionId: res.qID
            }));
            // const { data: response } = await SurveyService.assessAnswerScore({ choices: scorePayload });
        }

        //  Only Take Valid Responses ( logical )
        if (responseTracker?.length !== 0) {
            const filteredResponses = responses.filter(
                (response) => responseTracker.includes(response.qID) || response.qID === currentQuestion.id
            );
            // Submit All Answers
            await AnswerService.create({
                surveyToken: survey.survey_token,
                answers: filteredResponses,
                choices: scorePayload,
                meta: {
                    ...STORE_STATE.meta,
                    survey_completed_time: now()
                }
            });
        } else {
            // Submit All Answers
            await AnswerService.create({
                surveyToken: survey.survey_token,
                answers: responses,
                choices: scorePayload,
                meta: {
                    ...STORE_STATE.meta,
                    survey_completed_time: now()
                }
            });
        }

        actions.resetForm();
        RESET_RESPONSES_TO_STORE();
        if (survey.survey_type === 'Poll') {
            window.location.href = `/survey-results/${shortcode}`;
            // navigate('/survey-results');
        } else {
            window.location.href = '/survey-success';
        }
    };

    const validationSchema = yup.lazy((value) =>
        yup.object().shape({
            answer: yupValidatorSchema && yupValidatorSchema.generateSchema(currentQuestion.meta, value, currentQuestion)
        })
    );

    const fetchNextQuestion = async (currentQuestionID, values, actions) => {
        setAnimationDirection('next');
        const currentQuestion = find(questions, { id: currentQuestionID });
        if (!hasPrevious(currentQuestion)) {
            await AnswerService.surveyStarted(survey.survey_token);
            SET_DANGEROULSY_STATE_INTO_STORE({
                ...STORE_STATE,
                meta: {
                    survey_started_time: now()
                }
            });
        }

        ADD_RESPONSE_TO_STORE(currentQuestionID, values.answer, values.answered_at);
        const nextQuestion = getNextQuestion(currentQuestionID);
        actions.resetForm();
        TRACK_RESPONSES_TO_STORE(currentQuestionID, nextQuestion);
        setCurrentQuestion(nextQuestion);

        if (user) {
            // Tracks Response data on backend by cache only when the user is authenticate
            ResponseService.trackResponseOnCache({
                survey_id: survey.id,
                data: STORE_STATE
            });
        }
    };

    const fetchPrevQuestion = (currentQuestionID, formik) => {
        setAnimationDirection('prev');
        ADD_RESPONSE_TO_STORE(currentQuestionID, formik.values.answer, formik.values.answered_at);
        const prevQuestion = getPrevQuestion(currentQuestionID);
        setCurrentQuestion(prevQuestion);
        formik.setFieldValue('answer', '');
        if (user) {
            // Tracks Response data on backend by cache only when the user is authenticate
            ResponseService.trackResponseOnCache({
                survey,
                data: STORE_STATE
            });
        }
    };

    const currentQuestionChangeHandler = (currentQuestion) => {
        const Component = Loadable(lazy(() => import(`views/questions/${currentQuestion?.meta.identifier}`)));
        setSelectedQuestionComponent(<Component questionData={currentQuestion} />);
        setResolveCurrentQuestionAnswer((prevValue) => {
            const existingAnswer = find(responses, { qID: currentQuestion.id });
            if (existingAnswer) {
                return existingAnswer.answer;
            }
            return '';
        });
        SET_CURRENT_QUESTON_INTO_STORE(currentQuestion);
    };

    // It triggers next question/submit button
    const handleSkip = () => multiStepFormEl.current && multiStepFormEl.current.click();

    useEffect(() => {
        if (!isEmpty(currentQuestion)) {
            currentQuestionChangeHandler(currentQuestion);
            setYupValidatorSchema(new YupValidatorGenerator());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestion]);

    useEffect(() => {
        // if the respondent resume the survey then go to the last question he answered
        if (responseTracker.length > 0) {
            const currentQuestionID = responseTracker[responseTracker.length - 1];
            const nextQuestion = getNextQuestion(currentQuestionID);
            TRACK_RESPONSES_TO_STORE(currentQuestionID, nextQuestion);
            setCurrentQuestion(nextQuestion);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseTracker]);

    const multiStepFormProps = {
        currentQuestion,
        fetchNextQuestion,
        fetchPrevQuestion,
        multiStepFormEl,
        direction: animationDirection
    };

    const handleWelcomePageButton = (currentQuestionID) => {
        setAnimationDirection('next');
        const nextQuestion = getNextQuestion(currentQuestionID);
        setCurrentQuestion(nextQuestion);
    };

    const handleForwardButton = async (currentQuestionID) => {
        setAnimationDirection('next');
        setIsinitiallyLoaded(false);

        const nextQuestion = getNextQuestion(currentQuestionID);
        console.log('Flow 2', nextQuestion, responses, responseContainer);
        if (!isUndefined(nextQuestion)) {
            setCurrentQuestion(nextQuestion);

            ADD_RESPONSE_TO_STORE(currentQuestionID, '', new Date());

            TRACK_RESPONSES_TO_STORE(currentQuestionID, nextQuestion);
            setIsinitiallyLoaded(true);

            if (user) {
                // Tracks Response data on backend by cache only when the user is authenticate
                ResponseService.trackResponseOnCache({
                    survey_id: survey.id,
                    data: STORE_STATE
                });
            }
        } else {
            let scorePayload = [];

            if (survey.survey_type === 'Assessment' && responseContainer.responses.length > 0) {
                scorePayload = responseContainer.responses.map((res) => ({
                    choiceId: Number(res.answer.value),
                    questionId: res.qID
                }));
            }

            //  Only Take Valid Responses ( logical )
            if (responseTracker?.length !== 0) {
                const filteredResponses = responses.filter(
                    (response) => responseTracker.includes(response.qID) || response.qID === currentQuestion.id
                );
                // Submit All Answers
                await AnswerService.create({
                    surveyToken: survey.survey_token,
                    answers: filteredResponses,
                    choices: scorePayload,
                    meta: {
                        ...STORE_STATE.meta,
                        survey_completed_time: now()
                    }
                });
            } else {
                // Submit All Answers
                await AnswerService.create({
                    surveyToken: survey.survey_token,
                    answers: responses,
                    choices: scorePayload,
                    meta: {
                        ...STORE_STATE.meta,
                        survey_completed_time: now()
                    }
                });
            }

            // formik.resetForm();
            RESET_RESPONSES_TO_STORE();
            if (survey.survey_type === 'Poll') {
                window.location.href = `/survey-results/${shortcode}`;
                // navigate('/survey-results');
            } else {
                window.location.href = '/survey-success';
            }
        }
    };

    const handleBackOnScreen = (currentQuestionID) => {
        setAnimationDirection('prev');
        const prevQuestion = getPrevQuestion(currentQuestionID);
        setCurrentQuestion(prevQuestion);
    };

    useEffect(() => {
        renderInitialData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ThemeProvider theme={dynamicTheme}>
            <Box
                sx={{
                    ...dynamicTheme.customBackground.main
                }}
            >
                <ProgressBar
                    variant="determinate"
                    value={responseTracker.length > 0 ? (responseTracker.length / questions.length) * 100 : 0}
                />

                {/* Header Section */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        padding: { xs: '10px', sm: '20px' },
                        gap: { xs: '15px', sm: 0 }
                    }}
                >
                    <Box>
                        {survey?.meta?.logo && survey?.survey_logo ? (
                            <img src={`${survey?.survey_logo}`} alt="logo" height={40} />
                        ) : (
                            <img src={logo} alt="surveyFlip" width="40" />
                        )}
                    </Box>

                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        {currentQuestion?.meta?.identifier !== 'MessageQuestion' && (
                            <Typography variant="h6">{survey.survey_title}</Typography>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: '10px',
                            width: { sm: 'auto' },
                            position: { xs: 'absolute', sm: 'relative' },
                            right: { xs: '15px' }
                        }}
                    >
                        <StyledButton
                            variant="outlined"
                            size="small"
                            color="primary"
                            width="fit-content"
                            onClick={handleSkip}
                            disabled={!isEmpty(currentQuestion) && currentQuestion?.meta?.required}
                        >
                            Skip
                        </StyledButton>
                        <StyledButton variant="contained" color="secondary" size="small" onClick={handleQuit}>
                            Quit
                        </StyledButton>
                    </Box>
                </Box>

                {/* Main Content */}
                {isinitiallyLoaded && currentQuestion ? (
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            minHeight: { xs: 'calc(100vh - 200px)', sm: 'calc(100vh - 150px)' },
                            px: { xs: 2, sm: 3 }
                        }}
                    >
                        <Grid item xs={12}>
                            <Card
                                sx={{
                                    backgroundColor: 'transparent',
                                    border: '0',
                                    borderColor: 'divider',
                                    boxShadow: 'none',
                                    maxWidth: { xs: '100%', sm: 1170 },
                                    margin: '0 auto',
                                    padding: { xs: '20px', sm: '35px', md: '50px' },
                                    mx: { xs: 2, sm: 'auto' }
                                }}
                            >
                                <CardContent
                                    sx={{
                                        p: { xs: 1, sm: 2 },
                                        '&:last-child': { pb: { xs: 1, sm: 2 } }
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                width: '100%',
                                                minHeight: 'calc(100vh - 300px)',
                                                margin: '0 auto',
                                                '& form': {
                                                    '& input, & select, & textarea': {
                                                        fontSize: { xs: '16px', sm: 'inherit' }
                                                    }
                                                }
                                            }}
                                        >
                                            {!isEmpty(currentQuestion) &&
                                                !isEmpty(SelectedQuestionComponent) &&
                                                stateQuestions.length !== 0 &&
                                                (currentQuestion?.meta?.identifier === 'MessageQuestion' ? (
                                                    <MessageQuestion
                                                        questionData={currentQuestion}
                                                        handleWelcomePageButton={handleWelcomePageButton}
                                                        handleBackOnScreen={handleBackOnScreen}
                                                        handleForwardButton={handleForwardButton}
                                                    />
                                                ) : (
                                                    <MultiStepForm onSubmit={submitHandler} {...multiStepFormProps}>
                                                        <FormStep
                                                            initialValues={{
                                                                answer: resolveCurrentQuestionAnswer
                                                            }}
                                                            onSubmit={(values, actions) =>
                                                                fetchNextQuestion(currentQuestion.id, values, actions)
                                                            }
                                                            validationSchema={validationSchema}
                                                        >
                                                            {SelectedQuestionComponent}
                                                        </FormStep>
                                                    </MultiStepForm>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            minHeight: 'calc(100vh - 150px)',
                            maxWidth: { xs: '100%', sm: 1020 },
                            margin: '0 auto',
                            px: { xs: 2, sm: 3 }
                        }}
                    >
                        <Grid item xs={12}>
                            <ListSkeleton times={3} />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </ThemeProvider>
    );
};

SurveyLightTheme.propTypes = {
    survey: PropTypes.object
};

export default SurveyLightTheme;
