import React from 'react';
import { Button, useTheme } from '@mui/material';
import {
    IconArrowBack,
    IconArrowNarrowDown,
    IconArrowNarrowLeft,
    IconArrowNarrowRight,
    IconArrowNarrowUp,
    IconArrowUp,
    IconCaretDown,
    IconCaretUp
} from '@tabler/icons';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';
import PropTypes from 'prop-types';

const MultiStepFormNavigation = ({ onBackClick, currentQuestion, elementRef }) => {
    const hasPrevious = useMasterSurveyResponseStore((state) => state.hasPreviousQuestion);
    const isLastQuestion = useMasterSurveyResponseStore((state) => state.isLastQuestion);
    const theme = useTheme();

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    width: 'calc(100% - 100px)'
                }}
            >
                <div>
                    <p style={{ color: theme.typography.h2.color }}>SurveyFlip</p>
                </div>
                <div>
                    <Button
                        size="small"
                        variant="contained"
                        disabled={!hasPrevious(currentQuestion)}
                        type="button"
                        onClick={onBackClick}
                        sx={{
                            color: theme.button.color,
                            backgroundColor: theme.button.backgroundColor,
                            borderRadius: '4px 0 0 4px',
                            borderRight: '1px solid rgb(161, 161, 161)',
                            transition: 'opacity 0.2s ease-in-out',
                            '&:hover': {
                                backgroundColor: theme.button.backgroundColor,
                                color: theme.button.color,
                                opacity: 0.8,
                                boxShadow: 'none'
                            }
                        }}
                    >
                        <IconCaretUp />
                    </Button>
                    <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        ref={elementRef}
                        sx={{
                            boxShadow: 'none',
                            color: theme.button.color,
                            backgroundColor: theme.button.backgroundColor,
                            borderRadius: '0 4px 4px 0',
                            transition: 'opacity 0.2s ease-in-out',
                            '&:hover': {
                                backgroundColor: theme.button.backgroundColor,
                                color: theme.button.color,
                                boxShadow: 'none',
                                opacity: 0.8
                            }
                        }}
                    >
                        <IconCaretDown />
                    </Button>
                </div>
            </div>
        </>
    );
};

MultiStepFormNavigation.propTypes = {
    onBackClick: PropTypes.func,
    currentQuestion: PropTypes.object,
    elementRef: PropTypes.any
};

export default MultiStepFormNavigation;
