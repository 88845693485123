import React, { useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

// material-ui
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';

// project imports
import AuthCardWrapper from '../AuthCardWrapper';
import AuthWrapper1 from '../AuthWrapper1';

import Logo from 'ui-component/Logo';
import { useTheme } from '@mui/material/styles';
import AuthFooter from 'ui-component/cards/AuthFooter';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';
import SurveyService from 'services/survey.service';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets

// ==============================|| AUTH3 - CHECK MAIL ||============================== //

const SurveySuccessPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const [score, setScore] = React.useState(null);
    const [survey, setSurvey] = React.useState({});
    const [isSurveyDataLoaded, setIsSurveyDataLoaded] = React.useState(false);

    const surveyShortCode = useMasterSurveyResponseStore((state) => state.survey_short_code);
    const responseContainer = useMasterSurveyResponseStore((state) => state.responseContainer);

    const handleSurveyScoreOnAssessment = async () => {
        const { data } = await SurveyService.getSurveyFromShortCode(surveyShortCode);
        setSurvey(data);
        if (data.survey_type === 'Assessment' && responseContainer.responses.length > 0) {
            const scorePayload = responseContainer.responses.map((res) => ({
                choiceId: Number(res.answer.value),
                questionId: res.qID
            }));
            const { data: response } = await SurveyService.assessAnswerScore({ choices: scorePayload });
            setScore(response.score);
        }
    };

    useEffect(() => {
        handleSurveyScoreOnAssessment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (survey?.meta) {
            setIsSurveyDataLoaded(true);
        }
        setTimeout(() => {
            if (survey?.meta?.redirection_url) {
                window.location.href = survey?.meta?.redirection_url;
            }
        }, 3000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [survey]);

    return (
        <>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh', backgroundColor: '#ecf0f1' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper style={{ border: 'none' }}>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <RouterLink to="#">
                                            <Logo />
                                        </RouterLink>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center" justifyContent="center" textAlign="center">
                                            {isSurveyDataLoaded && !survey?.meta?.thankyou_text && (
                                                <Grid item xs={12}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        Thank you
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                {isSurveyDataLoaded && survey?.meta?.thankyou_text && (
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <AlertTitle dangerouslySetInnerHTML={{ __html: survey?.meta?.thankyou_text }} />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {isSurveyDataLoaded && !survey?.meta?.thankyou_text && (
                                                    <>
                                                        <Typography
                                                            variant="caption"
                                                            fontSize="18px"
                                                            textAlign={matchDownSM ? 'center' : ''}
                                                            sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}
                                                        >
                                                            You have successfully completed the survey.{' '}
                                                            {score && `Your Survey Completion score is ${score}.`}
                                                        </Typography>
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                href="/"
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                                color="secondary"
                                                                sx={{ color: 'white' }}
                                                            >
                                                                Go Home
                                                            </Button>
                                                        </AnimateButton>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </>
    );
};

export default SurveySuccessPage;
