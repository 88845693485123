import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SurveyService from 'services/survey.service';
import { CircularProgress, Box, Typography } from '@mui/material';
import SurveyLightTheme from './surveyLightTheme';

const SurveyHandler = () => {
    const { shortcode } = useParams();
    const [survey, setSurvey] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSurveyData = async () => {
            try {
                const { data } = await SurveyService.surveyHandler(shortcode);
                setSurvey(data);
            } catch (error) {
                console.error('Error fetching survey data:', error);
                if (error.code === 'error') {
                    navigate(error.redirect_to, {
                        state: {
                            code: error.code,
                            msg: error.msg
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
        };

        fetchSurveyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shortcode]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return <SurveyLightTheme survey={survey} />;
};

export default SurveyHandler;
