/* eslint-disable */
import { isUndefined } from 'lodash';
import * as yup from 'yup';

class YupValidatorGenerator {
    constructor() {}

    generateSchema(questionMeta, value, questionData) {
        const questionMetaKeys = Object.keys(questionMeta);

        let validator;

        // We have two different conditions
        // where there is no answer
        // and where there is an answer with only a space
        if (typeof value.answer === 'undefined' && questionMeta['required']) {
            validator = yup.string().trim().required('Answer is required');
        } else if (questionMeta['required'] && typeof value.answer === 'string') {
            validator = yup.string().trim().required('Answer is required');
        } else if (typeof value.answer === 'string') {
            validator = yup.string();
        } else {
            validator = yup.mixed();
        }

        //  Global Max Characters Restriction
        if (questionMetaKeys.includes('max_characters') && questionMeta['max_characters']) {
            validator = validator.max(
                questionMeta['max_characters_numbers'],
                `Answer must be ${questionMeta['max_characters_numbers']} characters or less`
            );
        }

        // Global Required Restriction
        if (questionMetaKeys.includes('required') && questionMeta['required']) {
            validator = validator.required('Answer is required');
        }

        //  Only For Name Question
        if (questionMeta.identifier === 'NameQuestion') {
            if (questionMeta.required) {
                const NameQuestionValidator = yup.object().shape({
                    first_name: yup.string().trim().required('First Name is required'),
                    last_name: yup.string().trim().required('Last Name is required')
                });
                return NameQuestionValidator;
            }
        }

        //  Only For Matrix Question
        if (questionMeta.identifier === 'MatrixQuestion') {
            if (questionMeta.required) {
                let MatrixQuestionValidator;
                if (!questionMeta.allow_multiple) {
                    MatrixQuestionValidator = yup
                        .array()
                        .test('test-if-user-filled-all-required-answer-fields', 'You must fill in all the Answer field', (answers) => {
                            return answers?.length > 0 && answers?.length === questionMeta?.rowColumnData?.rows?.length;
                        });
                } else {
                    MatrixQuestionValidator = yup
                        .array()
                        .test(
                            'test-if-user-filled-all-required-answer-fields',
                            `You must fill ${questionMeta.required_column} column(s) in all the Answer field`,
                            (answers) => {
                                return (
                                    answers?.length > 0 &&
                                    answers?.length === questionMeta?.rowColumnData?.rows?.length &&
                                    answers?.every((ans) => {
                                        const values = Object.values(ans)[0]; // Get the values of the object

                                        // Check if the values is an array with exactly 2 elements
                                        return Array.isArray(values) && values.length >= questionMeta.required_column;
                                    })
                                );
                            }
                        );
                }
                return MatrixQuestionValidator;
            }
        }

        //  Only For Multiple Choice Question which have allow_multiple as true
        if (questionMeta.identifier === 'McqQuestion' && questionMeta.required && questionMeta.allow_multiple) {
            const MultipleMcqQuestionValidator = yup.object().test('test-mcq-answer', 'Answer is required', (value) => {
                // Check if the answer is valid
                // Failed validation if other is false and data array is empty
                if (value?.other === false && (!value?.data || value.data.length === 0)) {
                    return false;
                }
                // Basic Required Validation
                if (isUndefined(value)) {
                    return false;
                }

                return true;
            });
            return MultipleMcqQuestionValidator;
        }

        //  Only For Matrix Dynamic and Matrix Dropdown Question
        if (questionMeta.identifier === 'MatrixDynamicQuestion' || questionMeta.identifier === 'MatrixDropdownQuestion') {
            if (questionMeta.required) {
                const MatrixDynamicAndDropdownQuestionValidator = yup
                    .array()
                    .test(
                        'test-if-user-filled-all-required-answer-fields',
                        `You must fill ${questionMeta.required_column} column(s) in all the Answer field`,
                        (answers) => {
                            console.log('ff', answers);
                            return (
                                answers?.length > 0 &&
                                answers?.length === questionMeta?.rowColumnData?.rows?.length &&
                                answers?.every(
                                    (t) =>
                                        Object.values(t?.value)
                                            ?.filter((ans) => typeof ans !== 'undefined' && ans !== '')
                                            ?.map((ans) => ans.trim())?.length >= questionMeta.required_column
                                )
                            );
                        }
                    );
                return MatrixDynamicAndDropdownQuestionValidator;
            }
        }

        if (questionMeta.identifier === 'PhoneNumberQuestion') {
            validator = validator.test('no-negative', 'Answer cannot be negative', (val) => !val || !val.toString().trim().startsWith('-'));
        }

        if (questionMeta.identifier === 'EmailQuestion') {
            validator = validator.email('Invalid email address');
        }

        return validator;
    }
}
export default YupValidatorGenerator;
