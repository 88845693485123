import { Form, Formik } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';
import MultiStepFormNavigation from './MultiStepFormNavigation';
import PropTypes from 'prop-types';
import { Box, Button, useTheme } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

const MultiStepForm = ({ children, onSubmit, currentQuestion, fetchNextQuestion, fetchPrevQuestion, multiStepFormEl, direction }) => {
    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(children);
    const step = steps[stepNumber];
    const [snapshot, setSnapshot] = useState(step?.props.initialValues);
    const totalSteps = steps.length;
    const isLastQuestion = useMasterSurveyResponseStore((state) => state.isLastQuestion);
    const [contentHeight, setContentHeight] = useState(300); // Default height
    const contentRef = useRef(null);
    const theme = useTheme();

    // Effect to measure and update content height when question changes
    useEffect(() => {
        if (contentRef.current) {
            const updateHeight = () => {
                // Additional null check before using requestAnimationFrame
                if (!contentRef.current) return;

                // Use requestAnimationFrame to avoid layout thrashing
                requestAnimationFrame(() => {
                    // Another null check before accessing scrollHeight
                    if (!contentRef.current) return;

                    const newHeight = contentRef.current.scrollHeight;
                    setContentHeight(newHeight > 300 ? newHeight : 300); // Minimum 300px
                });
            };

            // Update immediately
            updateHeight();

            // Use ResizeObserver with debouncing to prevent loops
            const resizeObserver = new ResizeObserver(() => {
                // Debounce the height updates
                window.cancelAnimationFrame(resizeObserver.rafId);
                resizeObserver.rafId = window.requestAnimationFrame(updateHeight);
            });

            resizeObserver.observe(contentRef.current);

            return () => {
                window.cancelAnimationFrame(resizeObserver.rafId);
                resizeObserver.disconnect();
            };
        }
        return null;
    }, [currentQuestion, step]);

    const questionTemplateRenderHandler = (template, extraInfo) => React.cloneElement(template, extraInfo);

    // eslint-disable-next-line consistent-return
    const handleSubmit = async (values, actions) => {
        console.log(values);

        if (step.props.onSubmit) {
            await step.props.onSubmit(values);
        }

        if (isLastQuestion(currentQuestion)) {
            return onSubmit(values, actions);
            // eslint-disable-next-line no-else-return
        } else {
            actions.setTouched({});
            fetchNextQuestion(currentQuestion.id);
        }
    };

    const onSubmitHandler = () => {
        console.log(step);
    };

    // Animation variants for vertical sliding - reduce the extreme values
    const variants = {
        enter: (direction) => ({
            y: direction === 'next' ? 400 : -400,
            opacity: 0
        }),
        center: {
            y: 0,
            opacity: 1
        },
        exit: (direction) => ({
            y: direction === 'next' ? -50 : 50,
            opacity: 0
        })
    };

    return (
        <>
            <Formik
                initialValues={step?.props.initialValues}
                onSubmit={isLastQuestion(currentQuestion) ? onSubmit : step?.props.onSubmit}
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={step?.props.validationSchema}
            >
                {(formik) => (
                    <Form style={{ height: '100%', alignContent: 'center' }}>
                        <Box
                            sx={{
                                position: 'relative',
                                minHeight: `${contentHeight}px`,
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'visible'
                            }}
                        >
                            <AnimatePresence mode="wait" initial={false} custom={direction}>
                                <motion.div
                                    ref={contentRef}
                                    key={currentQuestion.id}
                                    custom={direction}
                                    variants={variants}
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    transition={{
                                        type: 'spring',
                                        stiffness: 120, // Reduced from 200
                                        damping: 20, // Reduced from 25
                                        mass: 0.5, // Reduced from 0.8
                                        opacity: { duration: 0.2 } // Increased from 0.2
                                    }}
                                    layout="position" // Changed from just 'layout'
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        willChange: 'transform, opacity',
                                        transformOrigin: 'center center' // Added for smoother scaling
                                    }}
                                >
                                    {questionTemplateRenderHandler(step, { formik })}

                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: 50,
                                            marginLeft: 19,
                                            justifyContent: 'left'
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            sx={{
                                                boxShadow: 'none',
                                                color: theme.button.color,
                                                backgroundColor: theme.button.backgroundColor,
                                                transition: 'opacity 0.1s ease-in-out',
                                                '&:hover': {
                                                    backgroundColor: theme.button.backgroundColor,
                                                    color: theme.button.color,
                                                    boxShadow: 'none',
                                                    opacity: 0.8
                                                }
                                            }}
                                            size="large"
                                            variant="contained"
                                            ref={multiStepFormEl}
                                        >
                                            {isLastQuestion(currentQuestion) ? 'Submit' : 'Next'}
                                        </Button>
                                    </div>
                                </motion.div>
                            </AnimatePresence>
                        </Box>

                        <MultiStepFormNavigation
                            currentQuestion={currentQuestion}
                            onBackClick={() => fetchPrevQuestion(currentQuestion.id, formik)}
                            elementRef={multiStepFormEl}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

MultiStepForm.propTypes = {
    children: PropTypes.element,
    onSubmit: PropTypes.func,
    currentQuestion: PropTypes.object,
    fetchNextQuestion: PropTypes.func,
    fetchPrevQuestion: PropTypes.func,
    multiStepFormEl: PropTypes.any,
    direction: PropTypes.string
};

export default MultiStepForm;
