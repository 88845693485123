import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';

// ===========================|| AUTH GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, user, logout } = useAuth();
    const navigate = useNavigate();

    const handleRoleBasedRedirection = async () => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
        if (user?.user_role && user?.user_role?.length === 0) {
            navigate('/check-role', { replace: true });
        }

        if (user?.user_role.includes('Client')) {
            const serviceToken = localStorage.getItem('serviceToken');
            await logout();
            window.location.href = `${process.env.REACT_APP_CLIENT_URL}/#/sso?token=${serviceToken}`;
        }
    };

    useEffect(() => {
        handleRoleBasedRedirection();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, navigate, user]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
