// project imports
import MinimalLayout from 'layout/MinimalLayout';
import SurveySuccessPage from 'views/loggedin-page/SurveySuccessPage';
import SurveyResults from 'views/loggedin-page/SurveyResults';
import Error from 'views/pages/maintenance/Error';
import SurveyHandler from 'views/surveys/surveyHandler';
import UpdateRole from 'views/loggedin-page/UpdateRole';
import ComingSoon2 from 'views/pages/maintenance/ComingSoon/ComingSoon2';
import GenericSurveyError from 'views/loggedin-page/GenericSurveyError';
import Privacy from 'views/pages/static/privacy/Privacy';
import Terms from 'views/pages/static/terms/Terms';
import SurveyCompletedResults from 'views/loggedin-page/SurveyCompletedResults';
import BrowsershotSurvey from 'views/BrowsershotSurvey';
import OnePageSurveyHandler from 'views/surveys/onePageSurveyHandler';

// ==============================|| Other ROUTES ||============================== //

const OtherRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <Error />
        },
        {
            path: 's/:shortcode',
            element: <SurveyHandler />
        },
        {
            path: 'onepage/:shortcode',
            element: <OnePageSurveyHandler />
        },
        {
            path: 'survey-success',
            element: <SurveySuccessPage />
        },
        {
            path: 'browsershot/screenshot/generator/:surveyToken',
            element: <BrowsershotSurvey />
        },
        {
            path: 'survey-results/:shortcode',
            element: <SurveyResults />
        },
        {
            path: 'completed-survey-results/:shortcode',
            element: <SurveyCompletedResults />
        },
        {
            path: 'not-found',
            element: <GenericSurveyError />
        },
        {
            path: 'access-denied',
            element: <GenericSurveyError />
        },
        {
            path: 'quota-unavailable',
            element: <GenericSurveyError />
        },
        {
            path: 'survey-expired',
            element: <GenericSurveyError />
        },
        {
            path: 'update-role/:role',
            element: <UpdateRole />
        },
        {
            path: '/maintenance',
            element: <ComingSoon2 />
        },
        {
            path: '/terms',
            element: <Terms />
        },
        {
            path: '/priacy',
            element: <Privacy />
        }
    ]
};

export default OtherRoutes;
