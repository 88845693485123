/* eslint-disable class-methods-use-this */
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import axiosServices from 'utils/axios';

class SurveyService {
    async create(data) {
        const response = await axiosServices.post('surveys/create', data);
        return response;
    }

    async getSurveyType() {
        const response = await axiosServices.get('surveys/survey_types');
        return response;
    }

    async list(params) {
        const response = await axiosServices.get(`respondents/surveys?${params}`);
        return response;
    }

    async completedSurveyList(params) {
        const response = await axiosServices.get(`respondents/completed-surveys?${params}`);
        return response;
    }

    async unfinishedSurveyList() {
        const response = await axiosServices.get(`respondents/unfinished-surveys`);
        return response;
    }

    async singleUnfinishedSurveyList(surveyToken) {
        const response = await axiosServices.get(`respondents/unfinished-surveys/${surveyToken}`);
        return response;
    }

    async getFirstQuestion(surveyToken) {
        const response = await axiosServices.get(`/survey/${surveyToken}/get-first-question`);
        return response;
    }

    async getQuesitonData(surveyToken, questionId, redirectingQuestion) {
        const response = await axiosServices.get(`/survey/${surveyToken}/question/${questionId}`, {
            params: {
                redirectingQuestion
            }
        });
        return response;
    }

    async getNextQuestionId(surveyToken, questionId, responses) {
        const response = await axiosServices.get(`/survey/${surveyToken}/question/${questionId}/get-next-question-id`, {
            params: {
                responses
            }
        });
        return response;
    }

    async getPreviousQuesiton(surveyToken, questionId) {
        const response = await axiosServices.get(`/survey/${surveyToken}/question/${questionId}/get-prev-question`);
        return response;
    }

    async getProfileSurvey(surveyToken, questionId) {
        const response = await axiosServices.get(`/survey/${surveyToken}/question/${questionId}/get-prev-question`);
        return response;
    }

    async getSingleSurvey(surveyToken) {
        const response = await axiosServices.get(`/surveys/show/${surveyToken}`);
        return response;
    }

    async getScreenOut(surveyToken) {
        const response = await axiosServices.get(`/survey/${surveyToken}/11/get-screen-out`);
        return response;
    }

    async creatScreenOut(surveyToken, info) {
        const response = await axiosServices.post(`/survey/${surveyToken}/create-screen-out`, info);
        return response;
    }

    async getCountries() {
        const response = await axiosServices.get(`/countries`);
        return response;
    }

    async getStates() {
        const response = await axiosServices.get(`/states`);
        return response;
    }

    async captchaSolver(token) {
        const response = await axiosServices.get(`/captcha-solver/${token}`);
        return response;
    }

    async stripeConnect() {
        const response = await axiosServices.get('/payments/handle-connect/stripe');
        return response;
    }

    // async mapShortLink(shortCode) {
    //     const response = await axiosServices.get(`/s/${shortCode}`);
    //     return response;
    // }

    async surveyHandler(shortCode) {
        const response = await axiosServices.get(`/s/${shortCode}`);
        return response;
    }

    async getSurveyFromShortCode(shortCode) {
        const response = await axiosServices.get(`get-survey-from-shortcode/${shortCode}`);
        return response;
    }

    async isResumedSurvey(surveyId, userId) {
        const response = await axiosServices.get(`/answers/resumed-survey/survey/${surveyId}/user/${userId}`);
        return response;
    }

    async removeCurrentAnswerFromResponses(surveyToken, questionId, responses) {
        const response = await axiosServices.get(`/survey/${surveyToken}/question/${questionId}/remove-current-answer-from-responses`, {
            params: {
                responses
            }
        });
        return response;
    }

    async assessAnswerScore(data) {
        const response = await axiosServices.post('/assess-answer', data);
        return response;
    }

    async getGraphResponse(surveyToken) {
        const response = await axiosServices.get(`/surveys/${surveyToken}/get-graph-responses`);
        return response;
    }

    async show(surveyToken) {
        const response = await axiosServices.get(`surveys/show/${surveyToken}`);
        return response;
    }

    async fetchSurveyInfo(surveyToken) {
        const response = await axiosServices.get(`surveys/fetch_survey_info/${surveyToken}`);
        return response;
    }

    async showQuestionsFromShortcode(shortcode, params) {
        const response = await axiosServices.get(`surveys/questions-from-shortcode/${shortcode}/get`);
        return response;
    }
}

export default new SurveyService();
